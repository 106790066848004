<template>
  <footer class="bg-black text-white mt-8 py-8 lg:py-4">
    <Container>
      <div class="lg:flex lg:justify-between lg:items-end">
        <LogoFooter />
        <nav class="flex mt-4 lg:mt-0 lg:align-bottom">
          <NuxtLink 
          v-for="link in footerLinks" 
          :to="link.slug"
          class="underline text-sm md:text-base mr-3 md:mr-6 lg:ml-4 lg:mr-0 lg:px-4"
          >
            <span class="xl:hidden">{{ link.title }}</span>
            <span class="hidden xl:inline">{{ link.titleLong }}</span>
          </NuxtLink>
          <button 
          class="underline text-sm md:text-base lg:ml-4 lg:mr-0 lg:px-4" 
          :class="{ 'opacity-30 cursor-wait' : helpLinkClicked }"
          :disabled="helpLinkClicked"
          @click="helpDesk"
          >
            Help center
          </button>
        </nav>
      </div>
    </Container>
  </footer>
</template>

<script setup>
const helpLinkClicked = ref(false)
const helpActive = ref(false)

const user = useSupabaseUser()
const userStore = useUserStore()
const { profile } = storeToRefs(userStore)

const footerLinks = [
  {
    title: 'Privacy',
    slug: '/policy/privacy',
    titleLong: 'Privacy policy'
  },
  {
    title: 'Cookies',
    slug: '/policy/cookies',
    titleLong: 'Cookie policy'
  },
  {
    title: 'Terms',
    slug: '/policy/terms',
    titleLong: 'Terms of service'
  }
]

const intercomObject = ref({
  api_base: 'https://api-iam.intercom.io',
  app_id: 'iazml3fk'
})

if (user.value && profile.value?.id) {
  intercomObject.value = {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'iazml3fk',
    user_id: profile.value.id,
    name: profile.value?.first_name,
    email: profile.value?.email,
    created_at: profile.value?.created_at
  }
}

const helpDesk = async () => {
  if (helpActive.value) return

  if (user.value && profile.value?.id) {
    const hash = await $fetch('/api/intercom/hash', {
      method: 'POST',
      body: JSON.stringify({
        user_id: user.value.id
      })
    })

    if (!hash) return

    intercomObject.value.user_hash = hash
  }

  useHead({
    script: [
      {
        innerHTML: `
          window.intercomSettings = {
            ...${JSON.stringify(intercomObject.value)}
          };
        `,
        type: 'text/javascript'
      },
      {
        innerHTML: `
          (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/iazml3fk';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();Intercom('show');
        `,
        type: 'text/javascript'
      }
    ]
  })
  helpActive.value = true
  helpLinkClicked.value = true
  setTimeout(() => {
    helpLinkClicked.value = false
  }, 8000)
}

</script>